<template>
  <div>
    <div v-if="isForm === 'login'" class="login">
      <div class="text-center mb-4">
        <h4>Faça login para acessar sua conta no QuestõesPRO</h4>
      </div>
      <form @submit.prevent="onLogin">
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            type="email"
            class="form-control"
            v-model="login.email"
            placeholder="Insira seu e-mail"
          />
        </div>

        <div class="form-group">
          <label for="userpassword">Senha</label>
          <input
            type="password"
            class="form-control"
            v-model="login.password"
            placeholder="Insira sua senha"
          />
        </div>
        <a href="#" class="text-light" @click.prevent="changeForm('recover')">
          <i class="mdi mdi-lock"></i> Esqueceu sua senha?
        </a>

        <div class="form-group">
          <div class="my-4">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="login.remember"
                id="remember"
              />
              <label class="custom-control-label" for="remember">
                Lembrar meu e-mail
              </label>
            </div>
          </div>
          <button type="submit" class="btn btn-lg btn-info btn-block">
            LOGIN
          </button>

          <div class="text-center mt-4">
            <p>
              Não possui uma conta?
              <a
                href="#"
                @click.prevent="changeForm('create')"
                class="text-info"
                >Cadastre-se já!</a
              >
            </p>
          </div>
        </div>
      </form>
    </div>
    <div v-if="isForm === 'create'" class="create">
      <div class="text-center mb-4">
        <h4>Crie agora sua conta no QuestõesPRO</h4>
      </div>

      <form @submit.prevent="onCreate">
        <b-form-group label="Nome de usuário" label-for="username">
          <b-form-input
            id="username"
            type="text"
            v-model="create.username"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="E-mail" label-for="email">
          <b-form-input
            id="email"
            type="email"
            v-model="create.email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Senha" label-for="password">
          <b-form-input
            id="password"
            type="password"
            v-model="create.password"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Confirmar senha" label-for="cpassword">
          <b-form-input
            id="cpassword"
            type="password"
            v-model="create.confirmPassword"
            required
            :class="[
              {
                'is-invalid':
                  create.confirmPassword.length > 0 &&
                  !$v.create.confirmPassword.sameAsPassword,
              },
              {
                'is-valid':
                  create.confirmPassword.length > 0 &&
                  $v.create.confirmPassword.sameAsPassword,
              },
            ]"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Plano" label-for="souce">
          <b-form-select
            v-model="create.plan_id"
            :options="plans"
          ></b-form-select>
        </b-form-group>
        <div class="form-group">
          <div class="custom-control custom-checkbox mb-4">
            <input
              id="customCheck1"
              type="checkbox"
              class="custom-control-input"
              v-model="create.accept"
              required
            />
            <label class="custom-control-label" for="customCheck1"
              >Eu aceito os <router-link to="/termos" target="_blank">termos e condições.</router-link></label
            >
          </div>
          <button type="submit" class="btn btn-lg btn-info btn-block">
            CRIAR CONTA
          </button>
          <div class="text-center mt-4">
            <p>
              Já possui uma conta?
              <a
                href="#"
                @click.prevent="changeForm('login')"
                class="text-info"
              >
                Faça login!
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
    <div v-if="isForm === 'recover'">
      <div class="text-center mb-4">
        <h4>Recuperar senha da sua conta no QuestõesPRO</h4>
      </div>
      <div class="alert alert-info" role="alert">
        Insira seu e-mail e instruções serão enviadas para você!
      </div>
      <form @submit.prevent="onRecoverPassword">
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            type="email"
            class="form-control"
            v-model="recover.email"
            placeholder="Insira seu e-mail"
          />
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-info btn-block mt-4">
            RECUPERAR
          </button>
          <div class="text-center mt-4">
            <p>
              Lembrou da sua senha?
              <a
                href="#"
                @click.prevent="changeForm('login')"
                class="text-info"
              >
                Faça login já!
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { authMethods } from "@/state/helpers";

import { sameAs } from "vuelidate/lib/validators";

import * as api from "@/api";

export default {
  name: "HomeForm",

  data() {
    return {
      isForm: "login",

      plans: [],
      plan: {},

      create: {
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        accept: false,
        referer: this.$route.query.referer,
        plan_id: ""
      },

      login: {
        email: "",
        password: "",
        remember: true,
      },

      recover: {
        email: "",
      },
    };
  },

  validations: {
    create: {
      confirmPassword: { sameAsPassword: sameAs("password") },
    },
  },
  mounted () {
    api
      .getAllPlans()
      .then((plans) => {
        this.loading = false;
        this.plans = plans.map(plan => ({
          value: plan.id,
          text: `${plan.name}: R$${String(plan.value).replace('.', ',')}`
        }))
        this.create.plan_id = this.plans[0].value
      })
      .catch(() => {
        this.loading = false;
        this.$swal("Opa", "Falha ao carregar planos", "error");
      });
    if(this.$route.query.referer) {
      this.isForm = "create"
    }
  },

  created() {
    try {
      this.login.remember = !!JSON.parse(
        localStorage.getItem("remember-me") || "true"
      );
      const lastEmail = localStorage.getItem("last-email");
      if (lastEmail) {
        this.login.email = lastEmail;
      }
    } catch (err) {
      localStorage.removeItem("remember-me");
      localStorage.removeItem("last-email");
    }
  },

  methods: {
    ...authMethods,

    onLogin() {
      localStorage.setItem("remember-me", JSON.stringify(this.login.remember));

      const { email, password } = this.login;

      this.logIn({ email, password })
        .then(() => {
          if (this.login.remember) {
            localStorage.setItem("last-email", email);
          }
          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          this.$swal("Opa", "Falha na autenticação", "error");
          this.login.password = "";
        });
    },

    onCreate() {
      if (this.create.password !== this.create.confirmPassword) {
        return this.$swal("Opa", "Senhas não correspondentes", "error");
      }

      if (!this.create.accept) {
        return this.$swal("Opa", "Você deve aceitar os termos", "error");
      }

      const create = {
        ...this.create,
      };

      this.register(create)
        .then(() => {
          return this.$router.push({
            name: "myInterests",
            query: { firstTime: 1 },
          });
        })
        .catch((error) => {
          let specificError = [];

          if (error.response && error.response.data) {
            if (
              Array.isArray(error.response.data.username) &&
              error.response.data.username.some(
                (item) => item.validation === "unique"
              )
            ) {
              specificError.push("Nome de usuário já existe!!");
            }

            if (
              Array.isArray(error.response.data.email) &&
              error.response.data.email.some(
                (item) => item.validation === "unique"
              )
            ) {
              specificError.push("E-mail já utilizado!!");
            }
          }

          if (specificError.length) {
            this.$swal("Opa", specificError.join("<br />"), "error");
            return;
          }
          this.$swal("Opa", "Algo deu errado", "error");
        });
    },

    onRecoverPassword() {
      api.forgotPassword(this.recover.email).finally(() => {
        this.$swal(
          "Sucesso",
          "Se este e-mail é válido você receberá um e-mail de recuperação!",
          "success"
        );
        this.recover.email = "";
      });
    },

    changeForm(form) {
      this.isForm = form;
    },
  },
};
</script>
