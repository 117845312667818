<template>
  <div>
    <header id="page-topbar" class="home-topbar">
      <div class="navbar-header container">
        <div class="mx-auto d-block">
          <div class="text-center">
            <h2 class="text-dark mx-2">QuestõesPRO</h2>
          </div>
        </div>
      </div>
    </header>

    <div class="header-block-background base-filter">
      <div class="mask color-filter"></div>
      <div class="container py-5">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-12">
            <div class="mx-lg-4 mx-3 my-4 text-light">
              <h2 class="mb-3">
                Mais do que treinar: viver profissionalmente do estudo
              </h2>
              <p>
                Conheça o site com a melhor metodologia de resolução de
                questões. No QuestõesPRO, mais do que um usuário, você é
                considerado um colaborador, que pode, inclusive, vir a ser
                remunerado! Confira, é gratuito por 10 dias!
              </p>
              <p>
                Tenha acesso a professores aprovados no concurso dos seus sonhos,
                com histórias de vida parecidas com sua. Aprenda com
                colaboradores mais experientes e se torne um membro do
                QuestõesPRO, o site mais avançado de resolução de questões!
              </p>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-12">
            <div class="card shadow-lg mx-lg-4 mx-3 my-2 bg-dark text-light">
              <div class="card-body">
                <HomeForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-dark py-5">
      <h2 class="text-center mb-4">Por que assinar o QuestõesPRO?</h2>
      <div class="row">
        <div class="col-md-6 col-12 my-2 px-4">
          <h4>Viva profissionalmente do estudo</h4>
          <p>
            Na comunidade QuestõesPRO, ser um estudante para concurso público é
            profissão. Veja como tornamos estudantes em verdadeiros
            colaboradores remunerados pela sua produção
          </p>
        </div>
        <div class="col-md-6 col-12 my-2 px-4">
          <h4>Seja recompensado pelo seu esforço</h4>
          <p>
            O nosso maior desejo é ver a sua evolução nos estudos. Justamente
            por isso, recompensamos os membros que demonstrem dedicação e afinco
            rumo aos seus objetivos
          </p>
        </div>
        <div class="col-md-6 col-12 my-2 px-4">
          <h4>Encontre professores</h4>
          <p>
            Tenha acesso a professores aprovados nos mais variados
            concursos e prontos para ajudá-lo a conquistar o seu
          </p>
        </div>
        <div class="col-md-6 col-12 my-2 px-4">
          <h4>Torne-se um parceiro nas mídias sociais</h4>
          <p>
            Ao divulgar a nossa comunidade, você recebe o status de Parceiro,
            usufrui vários benefícios e pode, até mesmo, vir a ser remunerado
          </p>
        </div>
        <div class="col-md-6 col-12 my-2 px-4">
          <h4>Acompanhe a sua métrica</h4>
          <p>
            Confira o nosso repertório estatísco e acompanhe em detalhes a
            evolução do seu rendimento
          </p>
        </div>
        <div class="col-md-6 col-12 my-2 px-4">
          <h4>Receba descontos</h4>
          <p>
            Receba periodicamente descontos incríveis em produtos e serviços
            para aprimorar os seus estudos. Basta ser um membro da comunidade
            QuestõesPRO
          </p>
        </div>
      </div>
    </div>
    <div class="bg-primary text-light p-3">
      <p class="text-center mb-0">
        ©
        {{ new Date().getFullYear() }}
        QuestõesPRO
      </p>
      <h3 class="text-center">
        QuestõesPRO
      </h3>
    </div>
  </div>
</template>
<script>
import { authMethods } from "@/state/helpers";

import HomeForm from "@/components/HomeForm";

export default {
  components: { HomeForm },
  data() {
    return {};
  },
  methods: {
    ...authMethods,

    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
  },
};
</script>
<style lang="scss">
.home-topbar {
  position: initial !important;
}

.header-block {
  position: relative;
  width: 100% !important;
  min-height: 750px;
  overflow: hidden;
  cursor: default;

  z-index: -1;

  &-background {
    width: 100% !important;
    background-image: url("../../assets/landing-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    //filter: blur(1px);
    min-height: 750px;
    z-index: 1;
  }

  &-content {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.base-filter {
  position: relative;
  overflow: hidden;
  cursor: default;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100% !important;
    height: 100%;
    background-attachment: fixed;
  }
}

.color-filter {
  background-color: rgba(18, 18, 18, 0.75);
  background-attachment: fixed;
}

.gallery-img {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
}
</style>
